import { createTheme } from "@mui/material/styles"
import color from "./_color"
import textStyle from "./_textStyle"

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: color.PRIMARY_500,
      contrastText: color.SHADES_000,
    },
    secondary: {
      main: color.SECONDARY_500,
    },
    error: {
      main: color.SYSTEM_ERROR_500,
    },
    lineGreen: {
      main: color.LINE_GREEN,
      contrastText: color.SHADES_000,
    },
    ...color,
  },
  typography: {
    fontFamily: '"Noto Sans TC", "Roboto", "Helvetica", "Arial", sans-serif',
    // Adding custom textStyle
    ...textStyle,
  },
  shape: {
    borderRadius: 8,
  },
  form: {
    label: {
      textColor: color.SHADES_600,
      asteriskColor: "red",
    },
    helperText: {
      textColor: color.SHADES_400,
    },
    borderColor: color.SHADES_300,
  },
  header: {
    backgroundColor: color.SHADES_50,
  },
  footer: {
    fontColor: color.SHADES_500,
    backgroundColor: color.SHADES_900,
  },
})

theme = createTheme(theme, {
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
        fullWidth: true,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true,
      },
      styleOverrides: {
        root: {
          transform: "none",
          color: theme.form.label.textColor,
          ...theme.typography.headingMediumRegular,
        },
        asterisk: {
          // color: theme.form.label.asteriskColor,
          display: "none",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          "label + &": {
            marginTop: theme.spacing(3),
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.SHADES_50,
          },
        },
        input: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.form.borderColor,
          borderRadius: 8,
          padding: "10px 12px",
          "&:focus": {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.form.borderColor,
          borderRadius: 8,
          "&:focus": {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.SHADES_000,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.form.helperText.textColor,
          ...theme.typography.headingSmallRegular,
          marginTop: theme.spacing(0.5),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-error .MuiInput-input": {
            borderColor: theme.palette.error.main,
          },
          "&.Mui-error .MuiSelect-select": {
            borderColor: theme.palette.error.main,
          },
        },
      },
    },
    // MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "20px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: theme.palette.SHADES_900,
          ...theme.typography.headingH5Heavy,
        },
      },
    },
  },
})

export default theme
