import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import clinicService from "@/services/clinicService"
// import { enqueueSnackbar } from "@/store/slice/snackbar"
import { fetchUserByToken } from "@/store/slice/user"

export const updateClinic = createAsyncThunk("clinic/updateClinic", async (value, thunkAPI) => {
  const { clinic } = thunkAPI.getState()
  try {
    let newData = { ...clinic.currentClinic, ...value }
    const data = await clinicService.updateClinic({
      clinicID: clinic.currentClinic.id,
      data: newData,
    })
    return data
  } catch (error) {
    console.log("Error", error.response)
    return thunkAPI.rejectWithValue()
  }
})

const initialState = {
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  isError: false,
  // clinic list
  list: null,
  // clinic object
  currentClinic: null,
}

export const clinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    setCurrentClinic: (state, { payload }) => {
      state.currentClinic = payload
        ? {
            subjectIDs: payload.subjects.map(subject => subject.id),
            skillIDs: payload.skills.map(skill => skill.id),
            googlePlaceID: payload.googlePlace ? payload.googlePlace.id : "",
            paymentTypeIDs: payload.paymentTypes.map(item => item.id),
            paymentInstallmentIDs: payload.paymentInstallments.map(item => item.id),
            ...payload,
          }
        : null
    },
  },
  extraReducers: {
    [fetchUserByToken.pending]: state => {
      state.isFetching = true
    },
    [fetchUserByToken.fulfilled]: (state, { payload }) => {
      const { clinics } = payload
      state.isFetching = false
      state.isSuccess = true
      state.list = clinics ? clinics : null
    },
    [fetchUserByToken.rejected]: state => {
      state.isFetching = false
      state.isError = true
    },
    [updateClinic.pending]: state => {
      state.isUpdating = true
    },
    [updateClinic.fulfilled]: (state, { payload }) => {
      const newClinic = {
        ...state.currentClinic,
        subjectIDs: payload.subjects.map(subject => subject.id),
        skillIDs: payload.skills.map(skill => skill.id),
        googlePlaceID: payload.googlePlace ? payload.googlePlace.id : "",
        paymentTypeIDs: payload.paymentTypes.map(item => item.id),
        paymentInstallmentIDs: payload.paymentInstallments.map(item => item.id),
        ...payload,
      }
      const newList = state.list.map(clinic =>
        clinic.id === newClinic.id ? { ...newClinic } : clinic
      )
      state = {
        ...state,
        isUpdating: false,
        isSuccess: true,
        list: newList,
        currentClinic: {
          ...newClinic,
        },
      }
      return state
    },
    [updateClinic.rejected]: state => {
      state.isUpdating = false
      state.isError = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentClinic } = clinicSlice.actions

export default clinicSlice.reducer
