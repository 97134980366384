import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authService from "@/services/authService"

const auth = authService.authValue()

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password, utmSource, utmCampaign, utmMedium, utmContent, utmTerm }, thunkAPI) => {
    try {
      const data = await authService.register(
        email,
        password,
        utmSource,
        utmCampaign,
        utmMedium,
        utmContent,
        utmTerm
      )
      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const login = createAsyncThunk("auth/login", async ({ username, password }, thunkAPI) => {
  try {
    const data = await authService.login(username, password)
    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout()
})

const initialState = auth ? { isLoggedIn: true, auth } : { isLoggedIn: false, auth: null }

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthToken: (state, action) => {
      localStorage.setItem("auth", JSON.stringify(action.payload))
      return { ...state, auth: { ...action.payload } }
    },
  },
  extraReducers: {
    [register.fulfilled]: state => {
      state.isLoggedIn = false
    },
    [register.rejected]: state => {
      state.isLoggedIn = false
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true
      state.auth = action.payload
      localStorage.setItem("auth", JSON.stringify(action.payload))
    },
    [login.rejected]: state => {
      state.isLoggedIn = false
      state.auth = null
    },
    [logout.fulfilled]: state => {
      state.isLoggedIn = false
      state.auth = null
    },
  },
})

export const { updateAuthToken } = authSlice.actions

export default authSlice.reducer
