import { createSlice } from "@reduxjs/toolkit"
import { fetchUserByToken } from "@/store/slice/user"

const initialState = {
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  isError: false,

  selectedClinic: null,
  clinicApplications: null,
  selectedDoctor: null,
  doctorApplications: null,
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setSelectedClinic: (state, { payload }) => {
      state.selectedClinic = payload ? payload : null
    },
    setSelectedDoctor: (state, { payload }) => {
      state.selectedDoctor = payload ? payload : null
    },
  },
  extraReducers: {
    [fetchUserByToken.pending]: state => {
      state.isFetching = true
    },
    [fetchUserByToken.fulfilled]: (state, { payload }) => {
      const { clinicApplications, doctorApplications } = payload
      state.isFetching = false
      state.isSuccess = true
      state.clinicApplications = clinicApplications ? clinicApplications : null
      state.doctorApplications = doctorApplications ? doctorApplications : null
    },
    [fetchUserByToken.rejected]: state => {
      state.isFetching = false
      state.isError = true
    },
  },
})

export const { setSelectedClinic, setSelectedDoctor } = applicationSlice.actions

export default applicationSlice.reducer
