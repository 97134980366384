import { authHeader, responseHandler, AP_PINMED_URL } from "@/helpers"
import { objectToUrlParams } from "@/utils/url"

export const createDoctorApplication = ({ userID, doctorID = "", data, status = "pending" }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      userID,
      doctorID,
      data, // must upload whole data object
      status,
    }),
  }

  return fetch(`${AP_PINMED_URL}/users/${userID}/doctor_applications`, requestOptions).then(
    responseHandler
  )
}

const updateDoctor = ({ doctorID, data }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ...data,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors/${doctorID}`, requestOptions).then(responseHandler)
}

export const getDoctorClinics = ({ doctorID }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }

  return fetch(`${AP_PINMED_URL}/doctors/${doctorID}/clinics`, requestOptions).then(responseHandler)
}

export const addDoctorClinics = ({ doctorID, clinicID }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      clinicID,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors/${doctorID}/clinics/create`, requestOptions).then(
    responseHandler
  )
}

export const removeDoctorClinics = ({ doctorID, clinicID }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      clinicID,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors/${doctorID}/clinics/delete`, requestOptions).then(
    responseHandler
  )
}

const createDoctor = ({ licenseId, licenseIssuedAt }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      licenseId,
      licenseIssuedAt,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors`, requestOptions).then(responseHandler)
  // backend respond new token
}

const updateReviewStatus = ({ id, reviewStatus }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      status: reviewStatus,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors/${id}/review`, requestOptions).then(responseHandler)
}

const updateLicenseInfo = ({ licenseId, newLicenseInfo }) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ...newLicenseInfo,
    }),
  }

  return fetch(`${AP_PINMED_URL}/doctors/license/${licenseId}`, requestOptions).then(
    responseHandler
  )
}

// const updateDoctor = data => {
//   const requestOptions = {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       ...authHeader(),
//     },
//     body: JSON.stringify({
//       ...data,
//     }),
//   }

//   return fetch(`${AP_PINMED_URL}/doctors`, requestOptions).then(responseHandler)
// }

export const getDoctor = id => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  return fetch(`${AP_PINMED_URL}/doctors/${id}`, requestOptions).then(responseHandler)
}

export const getDoctorListWithClinicMerge = ({ query, approved }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  let queryStringObj = {
    limit: 10,
    offset: 0,
    search: query,
  }

  if (approved !== null) {
    queryStringObj.approved = approved // true or false
  }

  const searchParams = objectToUrlParams(queryStringObj)

  return fetch(`${AP_PINMED_URL}/doctors/with_clinics_merge?${searchParams}`, requestOptions).then(
    responseHandler
  )
}

export const updateDoctorSkillOrder = ({ doctorID, skillID, order }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      order,
    }),
  }
  return fetch(
    `${AP_PINMED_URL}/doctors/${doctorID}/skills/${skillID}/update_order`,
    requestOptions
  ).then(responseHandler)
}

export const removeDoctorSkillOrder = ({ doctorID, skillID }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }
  return fetch(
    `${AP_PINMED_URL}/doctors/${doctorID}/skills/${skillID}/delete_order`,
    requestOptions
  ).then(responseHandler)
}

const doctorService = {
  // getById,
  createDoctor,
  updateDoctor,
  updateReviewStatus,
  updateLicenseInfo,
}

export default doctorService
