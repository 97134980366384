import Router from "next/router"
import { responseHandler, AP_PINMED_URL } from "@/helpers"

const auth = () => process.browser && JSON.parse(localStorage.getItem("auth"))

const login = (username, password) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  }

  return fetch(`${AP_PINMED_URL}/login`, requestOptions).then(responseHandler)
}

const register = (email, password, utmSource, utmCampaign, utmMedium, utmContent, utmTerm) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      utmSource,
      utmCampaign,
      utmMedium,
      utmContent,
      utmTerm,
    }),
  }

  return fetch(`${AP_PINMED_URL}/register`, requestOptions).then(responseHandler)
}

const logout = () => {
  localStorage.removeItem("auth")
  Router.push("/account/login")
}

const authService = {
  get authValue() {
    return auth
  },
  login,
  logout,
  register,
}

export default authService
