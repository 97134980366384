import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import doctorService from "@/services/doctorService"
// import { enqueueSnackbar } from "@/store/slice/snackbar"
import { fetchUserByToken } from "@/store/slice/user"

export const updateDoctor = createAsyncThunk("doctor/updateDoctor", async (value, thunkAPI) => {
  const { doctor } = thunkAPI.getState()
  try {
    let newData = { ...doctor.currentDoctor, ...value }
    const data = await doctorService.updateDoctor({
      doctorID: doctor.currentDoctor.id,
      data: newData,
    })
    return data
  } catch (error) {
    console.log("Error", error.response.data)
    return thunkAPI.rejectWithValue()
  }
})

// export const createDoctor = createAsyncThunk(
//   "doctor/createDoctor",
//   async ({ licenseId, licenseIssuedAt }, thunkAPI) => {
//     try {
//       const data = await doctorService.createDoctor({
//         licenseId,
//         licenseIssuedAt,
//       })
//       return data
//     } catch (error) {
//       console.log("Error", error.response.data)
//       return thunkAPI.rejectWithValue()
//     }
//   }
// )

// export const updateReviewStatus = createAsyncThunk(
//   "doctor/updateReviewStatus",
//   async ({ id, reviewStatus }, thunkAPI) => {
//     try {
//       const data = await doctorService.updateReviewStatus({ id, reviewStatus })
//       console.log(`updateReviewStatus`, data)
//       return data
//     } catch (error) {
//       console.log("Error", error.response.data)
//       return thunkAPI.rejectWithValue()
//     }
//   }
// )

const initialState = {
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  isError: false,
  // changed state
  // formPersonal: {},
  // formSelectSkills: {},
  // formInformation: {},
  // formCases: {},
  // clinic list
  list: null,
  // doctor object
  currentDoctor: null,
}

export const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    setCurrentDoctor: (state, { payload }) => {
      state.currentDoctor = payload
        ? {
            subjectIDs:
              Object.keys(payload.subjects).length === 0
                ? []
                : payload.subjects.map(subject => subject.id),
            skillIDs: payload.skills.map(skill => skill.id),
            googlePlaceID: payload.googlePlace ? payload.googlePlace.id : "",
            educations: Object.keys(payload.educations).length === 0 ? [] : payload.educations,
            experiences: Object.keys(payload.experiences).length === 0 ? [] : payload.experiences,
            ...payload,
          }
        : null
    },
    // doctorReviewStatusChanged: (state, action) => {
    //   if (state.currentDoctor) {
    //     state.currentDoctor.reviewStatus = action.payload
    //   }
    // },
    // doctorLicenseInfoChanged: (state, action) => {
    //   if (state.currentDoctor) {
    //     state.currentDoctor = { ...state.currentDoctor, ...action.payload }
    //   }
    // },
    // formPersonal: (state, action) => {
    //   return { ...state, formPersonal: { ...action.payload } }
    // },
    // formSelectSkills: (state, action) => {
    //   return { ...state, formSelectSkills: { ...action.payload } }
    // },
    // formInformation: (state, action) => {
    //   return { ...state, formInformation: { ...action.payload } }
    // },
    // formCases: (state, action) => {
    //   return { ...state, formCases: { ...action.payload } }
    // },
  },
  extraReducers: {
    [fetchUserByToken.pending]: state => {
      state.isFetching = true
    },
    [fetchUserByToken.fulfilled]: (state, { payload }) => {
      const { doctors } = payload
      state.isFetching = false
      state.isSuccess = true
      state.list = doctors ? doctors : null
    },
    [fetchUserByToken.rejected]: state => {
      state.isFetching = false
      state.isError = true
    },
    [updateDoctor.pending]: state => {
      state.isUpdating = true
    },
    [updateDoctor.fulfilled]: (state, { payload }) => {
      const newDoctor = {
        ...state.currentDoctor,
        subjectIDs: payload.subjects.map(subject => subject.id),
        skillIDs: payload.skills.map(skill => skill.id),
        googlePlaceID: payload.googlePlace ? payload.googlePlace.id : "",
        ...payload,
      }
      const newList = state.list.map(doctor =>
        doctor.id === newDoctor.id ? { ...newDoctor } : doctor
      )
      state = {
        ...state,
        isUpdating: false,
        isSuccess: true,
        list: newList,
        currentDoctor: {
          ...newDoctor,
        },
      }
      return state
    },
    [updateDoctor.rejected]: state => {
      state.isUpdating = false
      state.isError = true
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  // doctorReviewStatusChanged,
  // doctorLicenseInfoChanged,
  // formPersonal,
  // formSelectSkills,
  // formInformation,
  // formCases,
  setCurrentDoctor,
} = doctorSlice.actions

export default doctorSlice.reducer
