const color = {
  PRIMARY_800: "#195180",
  PRIMARY_900: "#0f3a6a",
  PRIMARY_300: "#97ebf5",
  PRIMARY_200: "#c2f9fb",
  PRIMARY_500: "#51bcde",
  PRIMARY_100: "#e0fbf9",
  SECONDARY_900: "#726e00",
  PRIMARY_700: "#28729f",
  PRIMARY_400: "#7bd8eb",
  PRIMARY_600: "#3b95be",
  SECONDARY_800: "#8a8500",
  SECONDARY_700: "#aba600",
  SECONDARY_600: "#ccc600",
  SECONDARY_300: "#f9f663",
  SECONDARY_500: "#eee800",
  SECONDARY_400: "#f4f03d",
  SECONDARY_200: "#fdfb97",
  SECONDARY_100: "#fefdcb",
  SHADES_900: "#0f172a",
  SHADES_700: "#334155",
  SHADES_600: "#475569",
  SHADES_800: "#1e293b",
  SHADES_500: "#64748b",
  SHADES_400: "#94a3b8",
  SHADES_300: "#cbd5e1",
  SHADES_100: "#f1f5f9",
  SHADES_200: "#e2e8f0",
  SHADES_50: "#f8fafc",
  SHADES_000: "#ffffff",
  SYSTEM_ERROR_900: "#db3345",
  SYSTEM_ERROR_100: "#ffe6da",
  SYSTEM_WARNING_900: "#dbb705",
  SYSTEM_ERROR_500: "#ff7e75",
  SYSTEM_WARNING_500: "#ffe545",
  SYSTEM_WARNING_100: "#fffacd",
  SYSTEM_SUCCESS_900: "#2c8316",
  SYSTEM_SUCCESS_500: "#8ad35b",
  SYSTEM_SUCCESS_100: "#ebfbd5",

  // others
  LINE_GREEN: "#00C300",
}

export default color
