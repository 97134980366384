import { createSlice } from "@reduxjs/toolkit"

const uiSlice = createSlice({
  name: "ui",
  initialState: { mobileDrawerOpen: false },
  reducers: {
    toggleMobileDrawerOpen: state => {
      state.mobileDrawerOpen = !state.mobileDrawerOpen
    },
  },
})

export const { toggleMobileDrawerOpen } = uiSlice.actions
export default uiSlice.reducer
