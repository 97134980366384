export const responseHandler = response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 403 || response.status === 401) {
        // auto logout if 401/403 response returned from api
        localStorage.removeItem("auth")
        location.reload(true)
      }

      const error = (data && data.error) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
