import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "@/services/userService"

export const fetchUserByToken = createAsyncThunk("user/fetchUserByToken", async (_, thunkAPI) => {
  try {
    const data = await userService.fetchByToken()
    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const initialState = {
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  isError: false,
  // user object
  currentUser: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [fetchUserByToken.pending]: state => {
      state.isFetching = true
    },
    [fetchUserByToken.fulfilled]: (state, { payload }) => {
      const { id, username, email, confirmedAt, name, phone, newEmail } = payload
      state = {
        ...initialState,
        isUpdating: false,
        isSuccess: true,
        currentUser: {
          id,
          username,
          email,
          name,
          phone,
          confirmedAt,
          newEmail,
        },
      }
      return state
    },
    [fetchUserByToken.rejected]: state => {
      state.isFetching = false
      state.isError = true
    },
  },
})

export default userSlice.reducer
