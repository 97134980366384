import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit"
import uiReducer from "./slice/ui"
import snackbarReducer from "./slice/snackbar"
import authReducer from "./slice/auth"
import userReducer from "./slice/user"
import doctorReducer from "./slice/doctor"
import clinicReducer from "./slice/clinic"
import applicationReducer from "./slice/application"

const appReducer = combineReducers({
  ui: uiReducer,
  snackbar: snackbarReducer,
})

const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  doctor: doctorReducer,
  clinic: clinicReducer,
  application: applicationReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
})
