// Note:
// It's a text style, React Native Format copy from zeplin
// Manually update lineHeight's value from 89.7 to "89.7px"

// _REGULAR fontWeight: normal = 400
// _MEDIUM fontWeight: 500 = 500
// _HEAVY fontWeight: bold = 700

const textStyle = {
  headingTitleHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 69,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "89.7px",
    letterSpacing: 1.38,
  },
  headingTitleMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 69,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "89.7px",
    letterSpacing: 1.38,
  },
  headingTitleRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 69,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "89.7px",
    letterSpacing: 1.38,
  },
  headingH1Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 57,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "74.1px",
    letterSpacing: 1.14,
  },
  headingH1Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 57,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "74.1px",
    letterSpacing: 1.14,
  },
  headingH1Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 57,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "74.1px",
    letterSpacing: 1.14,
  },
  headingH2Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 48,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "62.4px",
    letterSpacing: 0.96,
  },
  headingH2Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 48,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "62.4px",
    letterSpacing: 0.96,
  },
  headingH2Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 48,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "62.4px",
    letterSpacing: 0.96,
  },
  headingH3Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 40,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "52px",
    letterSpacing: 0.8,
  },
  headingH3Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 40,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "52px",
    letterSpacing: 0.8,
  },
  headingH3Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 40,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "52px",
    letterSpacing: 0.8,
  },
  headingH4Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 33,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "42.9px",
    letterSpacing: 0.66,
  },
  headingH4Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 33,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "42.9px",
    letterSpacing: 0.66,
  },
  headingH4Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 33,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "42.9px",
    letterSpacing: 0.66,
  },
  headingH5Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 28,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "36.4px",
    letterSpacing: 0.56,
  },
  headingH5Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 28,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "36.4px",
    letterSpacing: 0.56,
  },
  headingH5Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 28,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "36.4px",
    letterSpacing: 0.56,
  },
  headingH6Heavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 23,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "29.9px",
    letterSpacing: 0.46,
  },
  headingH6Medium: {
    fontFamily: "Noto Sans TC",
    fontSize: 23,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "29.9px",
    letterSpacing: 0.46,
  },
  headingH6Regular: {
    fontFamily: "Noto Sans TC",
    fontSize: 23,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "29.9px",
    letterSpacing: 0.46,
  },
  headingLargeHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "24.7px",
    letterSpacing: 0.38,
  },
  body19PxHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: 0.38,
  },
  headingLargeMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24.7px",
    letterSpacing: 0.38,
  },
  body19PxMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: 0.38,
  },
  headingLargeRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "24.7px",
    letterSpacing: 0.38,
  },
  body19PxRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 19,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: 0.38,
  },
  headingMediumHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "20.8px",
    letterSpacing: 0.32,
  },
  body16PxHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.32,
  },
  headingMediumMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20.8px",
    letterSpacing: 0.32,
  },
  body16PxMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.32,
  },
  headingMediumRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "20.8px",
    letterSpacing: 0.32,
  },
  body16PxRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.32,
  },
  body14PxHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.28,
  },
  body14PxMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 14,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.28,
  },
  body14PxRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0.28,
  },
  headingSmallHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 13,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "16.9px",
    letterSpacing: 0.26,
  },
  headingSmallMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 13,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "16.9px",
    letterSpacing: 0.26,
  },
  headingSmallRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "16.9px",
    letterSpacing: 0.26,
  },
  body12PxHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: 0.24,
  },
  body12PxMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: 0.24,
  },
  body12PxRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: 0.24,
  },
  headingXSmallHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 11,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "14.3px",
    letterSpacing: 0.22,
  },
  headingXSmallMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 11,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "14.3px",
    letterSpacing: 0.22,
  },
  headingXSmallRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 11,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "14.3px",
    letterSpacing: 0.22,
  },
  headingSubHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "11.7px",
    letterSpacing: 0.18,
  },
  body9PxHeavy: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: 0.18,
  },
  headingSubMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "11.7px",
    letterSpacing: 0.18,
  },
  body9PxMedium: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: 0.18,
  },
  headingSubRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "11.7px",
    letterSpacing: 0.18,
  },
  body9PxRegular: {
    fontFamily: "Noto Sans TC",
    fontSize: 9,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: 0.18,
  },
}

export default textStyle
