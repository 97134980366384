import { authHeader, responseHandler, AP_PINMED_URL } from "@/helpers"
import { objectToUrlParams } from "@/utils/url"

export const createClinicApplication = ({ userID, clinicID = "", data, status = "pending" }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      userID,
      clinicID,
      data, // must upload whole data object
      status,
    }),
  }

  return fetch(`${AP_PINMED_URL}/users/${userID}/clinic_applications`, requestOptions).then(
    responseHandler
  )
}

const updateClinic = ({ clinicID, data }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ...data,
    }),
  }

  return fetch(`${AP_PINMED_URL}/clinics/${clinicID}`, requestOptions).then(responseHandler)
}

export const getClinicDoctors = ({ clinicID }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }

  return fetch(`${AP_PINMED_URL}/clinics/${clinicID}/doctors`, requestOptions).then(responseHandler)
}

export const addClinicDoctors = ({ clinicID, doctorID, doctorData }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      doctorID,
      doctorData,
    }),
  }

  return fetch(`${AP_PINMED_URL}/clinics/${clinicID}/doctors/create`, requestOptions).then(
    responseHandler
  )
}

export const removeClinicDoctors = ({ clinicID, doctorID }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      doctorID,
    }),
  }

  return fetch(`${AP_PINMED_URL}/clinics/${clinicID}/doctors/delete`, requestOptions).then(
    responseHandler
  )
}

export const getClinicList = ({ query, approved }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  let queryStringObj = {
    limit: 10,
    offset: 0,
    search: query,
  }

  if (approved !== null) {
    queryStringObj.approved = approved // true or false
  }

  const searchParams = objectToUrlParams(queryStringObj)

  return fetch(`${AP_PINMED_URL}/clinics?${searchParams}`, requestOptions).then(responseHandler)
}

export const getClinicPaymentTypesList = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(`${AP_PINMED_URL}/clinics/payment_types`, requestOptions).then(responseHandler)
}

export const getClinicPaymentInstallmentsList = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return fetch(`${AP_PINMED_URL}/clinics/payment_installments`, requestOptions).then(
    responseHandler
  )
}

export const updateClinicSkillOrder = ({ clinicID, skillID, order }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      order,
    }),
  }
  return fetch(
    `${AP_PINMED_URL}/clinics/${clinicID}/skills/${skillID}/update_order`,
    requestOptions
  ).then(responseHandler)
}

export const removeClinicSkillOrder = ({ clinicID, skillID }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }
  return fetch(
    `${AP_PINMED_URL}/clinics/${clinicID}/skills/${skillID}/delete_order`,
    requestOptions
  ).then(responseHandler)
}

const clinicService = {
  updateClinic,
  createClinicApplication,
}

export default clinicService
