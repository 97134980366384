import { authHeader, responseHandler, AP_PINMED_URL } from "@/helpers"

const fetchByToken = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }

  return fetch(`${AP_PINMED_URL}/me`, requestOptions).then(responseHandler)
}

const updateUser = ({ userId, name, username, phone, email }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      name,
      phone,
      email,
      username,
    }),
  }

  return fetch(`${AP_PINMED_URL}/users/${userId}`, requestOptions).then(responseHandler)
}

export const resendConfirmationEmail = () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  }

  return fetch(`${AP_PINMED_URL}/users/resend_confirmation_email`, requestOptions).then(
    responseHandler
  )
}

export const verifyEmail = token => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  return fetch(`${AP_PINMED_URL}/users/verify?token=${token}`, requestOptions).then(responseHandler)
}

export const verifyNewEmail = token => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }

  return fetch(`${AP_PINMED_URL}/users/verify_new_email?token=${token}`, requestOptions).then(
    responseHandler
  )
}

export const sendResetPasswordEmail = email => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  }

  return fetch(`${AP_PINMED_URL}/users/send_reset_password_email`, requestOptions).then(
    responseHandler
  )
}

export const resetPassword = data => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }

  return fetch(`${AP_PINMED_URL}/users/reset_password`, requestOptions).then(responseHandler)
}

// const resendConfirmationEmail = async () =>
//   fetch(`${AP_PINMED_URL}/users/resend_confirmation_email`, requestOptions)
//     .then(responseHandler)

const userService = {
  fetchByToken,
  updateUser,
  verifyEmail,
  verifyNewEmail,
}

export default userService
