export const objectToUrlParams = obj => {
  const params = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(obj[key])
      params.push(`${encodedKey}=${encodedValue}`)
    }
  }

  return params.join("&")
}
